import { Button } from "@chakra-ui/react";
import { FileContainer } from "am-tax-fe-core";
import { FunctionComponent } from "react";
import { map, mapList } from "./upload-btn-utils";

interface Props {
    title: string;
    accept?: string;
    multiple?: boolean;
    onFileSelectionChanged: (files: FileContainer[] | FileContainer | undefined) => void;
}

const UploadButton: FunctionComponent<Props> = ({ title, accept = "*", multiple = false, onFileSelectionChanged }) => {
    const handleUploadClickBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
        const parent = e.currentTarget.parentNode as HTMLButtonElement;
        if (parent) {
            parent?.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) {
            onFileSelectionChanged(undefined);
        } else if (!multiple) {
            onFileSelectionChanged(map(files[0], 0));
        } else {
            onFileSelectionChanged(mapList(files));
        }
    };

    return (
        <>
            <input type="file" id="actual-btn" onChange={handleFileChange} accept={accept} multiple={multiple} hidden />
            <label htmlFor="actual-btn">
                <Button onClick={handleUploadClickBtn}>{title}</Button>
            </label>
        </>
    );
};

export default UploadButton;
